<template>
  <!-- 
  <h1>Usado para testes {{ tmp }}</h1>    
  <h1>em todas as janelas </h1>
  <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> |
    <router-link to="/qdd">QDD</router-link>    
  </nav>
  -->
  <router-view/>
 
</template>



<script>
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      token: null,
      username_api: "integracao_geral",
      password_api: "agtTAG57732#@#",
      tmp: null  // USADO PARA TESTES
    };
  },
  mounted() {
    localStorage.setItem("p_url_api_token","https://api2.transparencia.rr.gov.br/transparencia/oauth/token?grant_type=client_credentials");
    localStorage.setItem("p_url_api","https://api2.transparencia.rr.gov.br");      
    localStorage.setItem("p_url_uni_orc","https://api2.transparencia.rr.gov.br/transparencia/api/v1/unidades-orcamentarias");      
    this.generateToken();
    const lista_meses = [
        { value: '1', text: 'Janeiro' },
        { value: '2', text: 'Fevereiro' },
        { value: '3', text: 'Março' },
        { value: '4', text: 'Abril' },
        { value: '5', text: 'Maio' },
        { value: '6', text: 'Junho' },
        { value: '7', text: 'Julho' },
        { value: '8', text: 'Agosto' },
        { value: '9', text: 'Setembro' },
        { value: '10', text: 'Outubro' },
        { value: '11', text: 'Novembro' },
        { value: '12', text: 'Dezembro' }];
    localStorage.setItem("p_lis_mes",JSON.stringify(lista_meses));
    const anoAtual = new Date().getFullYear();
    const lista_anos = [];
    for (let ano = anoAtual; ano >= 2007; ano--) { lista_anos.push({value: ano})}  
    localStorage.setItem("p_lis_ano",JSON.stringify(lista_anos));      
  },
  methods: {
    // *********************************************** GERA TOKEN ********************
    async generateToken() {
      const credentials = {
        username: this.username_api,
        password: this.password_api
      };
      const url_api_token = localStorage.getItem("p_url_api_token");
      const url_api = localStorage.getItem("p_url_api");
      try {
        const response = await axios.post(url_api_token,null,{auth :credentials},);
        this.token = response.data["access_token"]; 
        localStorage.setItem('p_token_api', this.token);
        this.carregaUniOrc();
      } catch (error) {
        Swal.fire({confirmButtonColor: '#3085d6',title: 'Erro ao gerar token',
            html: '<img src="aruanatransp2.gif" style="width: 100px; height: auto;"> <p> Infelizmente não temos acesso aos dados da API hospedada na</p> <p>URL '+url_api+'</p> <p>'+error.message+'</p>',
            icon: '',confirmButtonText: 'Ok'});
      }
    },

    //***************************************CARREGA UNIDADES ORCAMENTARIAS *************/
    async carregaUniOrc() {
      const url_uni_orc = localStorage.getItem("p_url_uni_orc");
      const token_api =  localStorage.getItem("p_token_api");
      try {
        const response = await axios.get(url_uni_orc,{headers: {Authorization: `Bearer ${token_api}`}},);
        const lis_uni_orc = response.data; // 
        localStorage.setItem("p_lis_uni_orc",JSON.stringify(lis_uni_orc));   //   GUARDA LISTA DE UNIDADES ORCAMENTARIAS EM UMA STORADE
      } catch (error) {
        Swal.fire({
            confirmButtonColor: '#3085d6',
            title: 'Erro ao carregar unidades orçamentárias',
            html: '<img src="aruanatransp2.gif" style="width: 100px; height: auto;"> <p> Infelizmente não temos acesso aos dados da API hospedada na</p> <p>URL '+url_uni_orc+'</p> <p>'+error.message+'</p>',
            icon: '',confirmButtonText: 'Ok'});
      }
    }
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
